import React, { useState, useEffect } from 'react';
import { Badge, Button, Center, Divider, Flex, Group, Grid, Card, List, Loader, Table, Title, Text, TextInput, Stack, Space } from '@mantine/core';
import { useListState } from '@mantine/hooks';
import { BarChart } from '@mantine/charts';
import RelevanceLegend from './RelevanceLegend';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import cx from 'clsx';

function App() {
  const [keyword, setKeyword] = useState('');
  const [chosenTitle, setChosenTitle] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [relevanceScores, setRelevanceScores] = useState([]);
  const [titleIdeas, setTitleIdeas] = useState([]);
  const [outlineData, setOutlineData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingRelevance, setLoadingRelevance] = useState(false);
  const [loadingOutline, setLoadingOutline] = useState(false);
  const [loadingTitles, setLoadingTitles] = useState(false);

  // Handler for input change
  const handleInputChange = (event) => {
    setKeyword(event.target.value);
  };

  // Handler for clicking a title button
  const handleTitleClicked = (title) => {
    setChosenTitle(title);
    generateOutline();
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault(); // Prevent the form from causing a page reload.
    try {
      const response = await fetch('/search', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ query: keyword }),
      });
      const data = await response.json();
      setLoading(false);
      setSearchResults(data);
      console.log(data);
    } catch (error) {
      console.error('Error fetching search results:', error);
      // Handle errors, e.g., by setting an error state and showing an error message.
    }
  };

  const generateTitles = async (event) => {
    event.preventDefault(); 
    setLoadingTitles(true);
  
    const titlesArray = searchResults.map(result => result.title);
  
    try {
      const response = await fetch('/generateTitles', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query: keyword, titles: titlesArray }),
      });
  
      if (response.ok) {
        const data = await response.json();
        setTitleIdeas(data);
      } else {
        console.error('Failed to fetch title data:', response.statusText);
      }
      setLoadingTitles(false);
    } catch (error) {
      console.error('Error fetching title data:', error);
      setLoadingTitles(false);
    }
  };

  const generateOutline = async () => {
    setLoadingOutline(true);
  
    const contentArray = searchResults.map(result => result.content);
  
    try {
      const response = await fetch('/generateOutline', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query: keyword, title: chosenTitle }),
      });
  
      if (response.ok) {
        const data = await response.json();
        setOutlineData(data);
        console.log(data);
      } else {
        console.error('Failed to fetch outline data:', response.statusText);
      }
      setLoadingOutline(false);
    } catch (error) {
      console.error('Error fetching outline data:', error);
      setLoadingOutline(false);
    }
  };

  const calculateRelevance = async (event) => {
    event.preventDefault(); // Prevent the form from causing a page reload.
    setLoadingRelevance(true);
  
    // Filter out results with no word count or a word count less than 10
    const filteredResults = searchResults.filter(result => result.word_count && parseInt(result.word_count, 10) >= 10);
  
    // Map over the filtered results to make fetch requests
    const fetchPromises = filteredResults.map(result =>
      fetch('/calculateRelevance', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query: keyword, page: result.content }),
      })
      .then(response => response.json())
      .then(data => {
        // Handle the response
        const scores = data.scores.map(score => {
          const [name, relevance] = Object.entries(score)[0];
          return { name, relevance: parseInt(relevance, 10) }; // Parse the relevance score
        });
        return {
          url: result.url,
          title: result.title,
          scores
        };
      })
      .catch(error => {
        console.error('Error fetching relevance score for a document:', error);
        return null; // Return null in case of an error
      })
    );
  
    // Wait for all promises to resolve
    const documentsWithScores = await Promise.all(fetchPromises);
    // Filter out any null values that were added in case of an error
    const validDocumentsWithScores = documentsWithScores.filter(document => document !== null);
    setRelevanceScores(validDocumentsWithScores);
    setLoadingRelevance(false);
  };  

  // Prepare chart data
  let averageWordCount = 0;
  const chartData = searchResults.map((result, index) => ({
    label: `#${index + 1}: ${result.domain}`,
    Words: result.word_count,
  }));

  if (searchResults.length > 0) {
    // Filter out results without a valid word count (> 0) and then calculate the total
    const validResults = searchResults.filter(result => Number(result.word_count) > 0);
    const totalWordCount = validResults.reduce((total, current) => total + Number(current.word_count), 0);
    
    // Calculate the average only if there are valid results
    averageWordCount = validResults.length > 0 ? (totalWordCount / validResults.length) : 0;
}

  // Prepare table data
  const tableData = searchResults.map((element, index) => {
    const formattedWordCount = isNaN(element.word_count) ? 
      "Unable to load" : 
      Intl.NumberFormat('en-US').format(element.word_count);
  
    // Create a URL object from the element's URL
    const urlObj = new URL(element.url);
    const hasHash = urlObj.hash; // Check if there is a hash in the URL
    
    // Remove the hash from the URL
    urlObj.hash = '';
  
    return (
      <Table.Tr key={index}>
        <Table.Td>
          <a href={urlObj.href} target="_blank" rel="noopener noreferrer">{urlObj.href}</a>
          <Space w="md" />
          {hasHash && <Badge variant="default" size="sm">Featured Snippet</Badge>}
        </Table.Td>
        <Table.Td>{element.title}</Table.Td>
        <Table.Td>{formattedWordCount}</Table.Td>
      </Table.Tr>
    );
  });

  return (
      <div>
        <Grid justify="center" align="stretch">
        <Center>
          <Grid.Col span="auto">
            <Stack align="center">
              <Player
                autoplay
                loop
                src="./pondering.json"
                style={{ height: '200px', width: '200px' }}
              >
              </Player>
            </Stack>
          </Grid.Col>
          <Grid.Col span={10}>
            <h1>How long should my blog post be?</h1>
            <p>Discover how much you should write and ideas for what to write about.</p>
          </Grid.Col>
          </Center>
        </Grid>
        <Space h="md" />
        <Grid>
        <Grid.Col span={1}>&nbsp;</Grid.Col>
          <Grid.Col span={10}>
          <form onSubmit={handleSubmit}>
            <TextInput
            size="lg"
            radius="lg"
            label="Keyword"
            description="Enter the keyword you want to rank for"
            value={keyword}
            onChange={handleInputChange}
            />
            <Group justify="flex-end" mt="md">
            <Button color="#F81342" variant="filled" type="submit">Calculate</Button>
            </Group>
          </form>
      {loading ? (
        <div>
          <Center>
            <Loader color="#F81342" size="xl" type="bars" />
            <Space w="lg" />
            <i>Counting how many words the top-ranking pages wrote...</i>
          </Center>
        </div>
      ) : chartData.length > 0 ? (
        <div>
        <Title order={2}>Aim for around {Intl.NumberFormat('en-US').format(averageWordCount.toFixed(0))} words</Title>
        <BarChart
            h={600} // height of the chart
            data={chartData} // your data
            dataKey="label" // key in your data objects to use as the label on the x-axis
            connectNulls={false}
            yAxisProps={{ width: 80 }} // adjust the y-axis properties if needed
            valueFormatter={(value) => new Intl.NumberFormat('en-US').format(value)}
            referenceLines={[
              { 
                y: averageWordCount, 
                label: `Average word count: ${Intl.NumberFormat('en-US').format(Math.round(averageWordCount))}`, 
                color: 'red.6' 
              }
            ]}            
            series={[
                { name: 'Words', color: '#D6DFDD' }
            ]}
        />
        <Space h="md" />
        <Table highlightOnHover>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>URL</Table.Th>
              <Table.Th>Title on Google</Table.Th>
              <Table.Th>Word Count</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{tableData}</Table.Tbody>
        </Table>
        <Space h="xl" />
        <Grid justify="center" align="stretch">
        <Center>
          <Grid.Col span="auto">
            <Stack align="center">
              <Player
                autoplay
                loop
                src="./flying-man.json"
                style={{ height: '200px', width: '200px' }}
              >
              </Player>
            </Stack>
          </Grid.Col>
          <Grid.Col span={10}>
            <h2>What should I write about?</h2>
            <p>Let's start with some ideas for the title.</p>
            <form onSubmit={generateTitles}>
              <Group justify="flex-start" mt="md">
              <Button color="#F81342" variant="filled" type="submit">Generate 5 Title Ideas</Button>
              </Group>
          </form>
          </Grid.Col>
          </Center>
        </Grid>
        </div>
      ) : (
        <p></p> // Added this line for cases where there's no chart data
      )}
      <div>
          {loadingTitles ? (
            <div>
              <Center>
                <Loader color="#F81342" size="lg" type="bars" />
                <Space w="lg" />
                <i>Calling an ad man on Madison Avenue for ideas...</i>
              </Center>
            </div>
          ) : titleIdeas?.title_tags?.length > 0 ? (
            <div>
              <Stack
                align="center"
                justify="center"
                gap="sm"
              >
                <Title order={3}>Which title do you like best?</Title>
                <Text>Click a title to generate an outline.</Text>
                <Space w="md" />
              </Stack>
              <Flex
                direction="column"
                gap="xl"
                justify="flex-start"
                align="center"
              >
              {titleIdeas.title_tags.map((title, index) => (
              <Button
              key={index}
              variant="light"
              color="#F81342"
              size="lg"
              onClick={() => handleTitleClicked(title)}
            >
              {title}
            </Button>
            
              ))}
              </Flex>
            </div>
          ) : (
            <p></p> // Say something for cases where there's no title data
          )}
      </div>
      <div>
          {loadingRelevance ? (
            <div>
              <Loader color="yellow" size="lg" type="bars" />
              <i>Scoring how relevant each section of their pages is...</i>
            </div>
          ) : relevanceScores.length > 0 ? (
            <div>
            {relevanceScores?.map((document, index) => (
              <Grid grow>
                <Grid.Col span={4} key={index} style={{ marginBottom: '20px' }}>
                  {/* Increment index by 1 to start position count at 1 */}
                  <h3>{document.title}</h3>
                  <p><a href={document.url} rel="noopener noreferrer" target="_blank">{document.url}</a></p>
                  {/* Pass the scores to the RelevanceLegend component */}
                  <RelevanceLegend scores={document.scores} />
                </Grid.Col>
              </Grid>
            ))}
          </div>
          ) : (
            <p></p> // Say something for cases where there's no relevance data
          )}
      </div>
      <div>
        {loadingOutline ? (
          <div>
            <Group justify="center">
              <Loader color="#F81342" size="lg" type="bars" />
              <Space w="lg" />
              <i>Constructing an outline...</i>
            </Group>
          </div>
        ) : outlineData?.outline ? ( // Update the condition to check for outlineData.outline
          <div>
            <Space h="md" />
            <Center>
            <Text 
              size="xl"
              fw={900}variant="gradient" 
              gradient={{ from: '#F81342', to: 'yellow', deg: 90 }}
              >
                Your outline is ready ↯
              </Text>
              </Center>
              <Card shadow="sm" padding="lg" radius="md" withBorder style={{ backgroundColor: '#F8F7F7', padding: '40px' }}>
              <Card.Section inheritPadding>
                <Title order={1}>{outlineData.outline.title}</Title>
                {outlineData.outline.introduction.map((paragraph, index) => (
                  <List key={`intro-${index}`}>
                    <List.Item>{paragraph}</List.Item>
                  </List>
                ))}
              </Card.Section>
              <Card.Section inheritPadding>
                {outlineData.outline.sections.map((section, sectionIndex) => (
                  <React.Fragment key={`section-${sectionIndex}`}>
                    <Title order={2}>{section.title}</Title>
                    {section.subsections && (
                      <List>
                        {section.subsections.map((subsection, subIndex) => (
                          <List.Item key={`subsection-${sectionIndex}-${subIndex}`}>
                            {subsection}
                          </List.Item>
                        ))}
                      </List>
                    )}
                  </React.Fragment>
                ))}
              </Card.Section>
              </Card>
          </div>
        ) : (
          <p></p> // Added this line for cases where there's no outline
        )}
      </div>
      <div>
        <Space h="lg" />
        <Divider my="xs" label="About this tool" labelPosition="center" />
        <Text c="dimmed" size="sm">One way to quickly get a sense of how long your blog post should be is by looking at how long the pages that rank at the top of Google are. This tool averages the word count of the top-ranking pages to arrive at that number. If you feel you can write about the topic in less words while still comprehensively covering everything the reader would want to know, that's fine. If you have more to say than what others say, that's also fine. Word count is not as important as the quality of your content.</Text>
      </div>
      </Grid.Col>
      <Grid.Col span={1}>&nbsp;</Grid.Col>
      </Grid>
    </div>
  );
}

export default App;