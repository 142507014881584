import React from 'react';
import ReactDOM from 'react-dom';
import { MantineProvider } from '@mantine/core';
import '@mantine/core/styles.css';
import '@mantine/charts/styles.css';
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <MantineProvider defaultColorScheme="light"
      theme={{
        // You can customize the theme here
        // For example, to set a global font:
        fontFamily: 'Roboto, sans-serif',
      }}
    >
  <App />
    </MantineProvider>
  </React.StrictMode>,
  document.getElementById('root')
);