import React from 'react';
import { scaleSequential } from 'd3-scale';
import { interpolateGreens } from 'd3-scale-chromatic';
import { LegendItem, LegendLabel } from '@visx/legend';

// Assume the component receives `scores` as a prop
function RelevanceLegend({ scores }) {
  // Determine the range of relevance scores dynamically from the scores prop
  const maxRelevance = scores.length > 0 ? Math.max(...scores.map(d => d.relevance)) : 0;
  const minRelevance = scores.length > 0 ? Math.min(...scores.map(d => d.relevance)) : 0;

  // Create a sequential color scale based on the dynamic relevance scores
  const colorScale = scaleSequential([minRelevance, maxRelevance], interpolateGreens);

  return (
    <div>
      {scores.map((segment, i) => (
        <LegendItem key={`legend-item-${i}`} margin="0 5px">
          <svg width={20} height={20} style={{ margin: '2px 0' }}>
            <rect fill={colorScale(segment.relevance)} width={20} height={20} />
          </svg>
          <LegendLabel align="left" margin="0 0 0 10px">
            {segment.name} {/* Use segment names as labels */}
          </LegendLabel>
        </LegendItem>
      ))}
    </div>
  );
}

export default RelevanceLegend;